const Default = 'ver.3.37';

//Ver.1.00 2023-xx-xx 画面サンプルを提示
//Ver.2.00 2023-xx-xx 提供データに基づき商品マスタを作成
//Ver.3.00 2023-xx-xx 受注が可能な画面を追加
//Ver.3.08 2023-11-20 在庫表示を○×表記に変更
//Ver.3.09 2023-12-21 トップ画像を追加
//Ver.3.10 2023-12-27 ユーザ情報の更新エラーを修正
//Ver.3.11 2024-01-09 トップ画像を変更
//Ver.3.12 2024-01-15 unit_priceを小数点対応に変更
//Ver.3.13 2024-01-26 受注画面の詳細表示において、合計金額が正しく計算されない不具合を修正
//Ver.3.14 2024-xx-xx トップページの画像アップロード機能を追加
//Ver.3.15 2024-xx-xx CSVログの表示を変更（期限切れのものは非表示）
//Ver.3.16 2024-xx-xx 管理画面以外の画面よりunit_price（一般卸価格）を非表示に変更
//Ver.3.17 2024-08-12 スマホ対応画面の追加
//Ver.3.18 2024-08-13 Quaaga.jsによるバーコードリーダーを追加
//Ver.3.19 2024-08-13 購入品リストの商品内容を最新の商品マスタを参照するように変更
//Ver.3.20 2024-08-13 商品マスタの注残可否に基づいてユーザが発注時に注残管理を行うか商品ごとに指定できるように変更
//  商品マスタのpre_order_flagが注残の可否を表す。1の場合は注残不可、0の場合は注残可となる。
//  名称はpre_order_flagが「注残」、pre_order_requestが「注残管理」となる。
//  受注データにはpre_order_requestが追加され、1の場合は注残リクエストなし、0の場合は注残リクエストありとなる。
//  pre_order_flagが不可の場合は、pre_order_requestの変更は行えず、常にpre_order_requestには1が設定される。
//  管理画面の受注一覧から出力する銃中データCSVに反映される。
//Ver.3.21 2024-08-14 出荷データのCSV取り込み
//  出荷データCSVのみ取り込み実行時に変換処理を行うように変更
//Ver.3.22 2024-08-16 出荷データの表示を管理画面と発注画面の両方に追加、一括発注機能を追加
//Ver.3.23 2024-08-20 出荷データのIDをoerder_noとitem_idの組み合わせに変更
//Ver.3.24 2024-08-21 出荷データに対するキャンセルフラグを追加
//Ver.3.25 2024-08-22 購入品リストから在庫切れのものがカートに入れられなかったものを修正、フィルター表示をON/OFF出来るように追加
//Ver.3.26 2024-10-15 出荷データのCSV取り込み時に、必須項目のチェックを数値比較で行っていたため0値がエラーとなっていた不具合を修正
//Ver.3.27 2024-10-16 カートへ入れる際の出荷単位と数量のバグを修正
//Ver.3.28 2024-10-16 管理画面の出荷データ一覧において、キャンセルデータを赤い背景色で表示するように変更
//Ver.3.29 2024-10-23 バーコードリーダーの読み取り結果を表示するように変更。連続して読み込まないようにインターバルを設定
//Ver.3.30 2024-10-24 注残機能と一部機能を非表示にしたリリースバージョン
//Ver.3.31 2024-11-20 一括発注機能にカートに入れる前の確認画面を追加
//Ver.3.32 2024-11-20 管理画面の受注データCSV出力において0が空白になっていた不具合を修正
//Ver.3.33 2024-11-22 一括発注機能において、カートに入れる前の確認画面で最低出荷単位によって個数が変わるものだけを表示するように変更
//Ver.3.34 2024-11-22 管理画面において注残管理の表示を非表示に変更
//Ver.3.35 2024-11-28 発注画面において注残管理の表示を非表示に変更
//Ver.3.36 2024-11-28 3.30で非表示にした項目を再表示
//Ver.3.37 2024-12-10 送料計算のバグを修正

export default Default;

