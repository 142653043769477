import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import CSVDownload from '../../../Objects/CSVDownload';
import { textFilter, numberFilter } from 'react-bootstrap-table2-filter';


const Default = () => {
	
	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [csv1, setCSV1] = useState([]);
	const [csv2, setCSV2] = useState([]);
	const [csvName1, setCsvName1] = useState(null);
	const [csvName2, setCsvName2] = useState(null);
	const [selectIDs, setSelectIDs] = useState([]);
	const [alert, setAlert] = useState([]);
	const [showFilter, setShowFilter] = useState(sessionStorage.getItem("showFilter") === 'false' ? false : true);
	
	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Order.Scan();
				//console.log(ref);
				
				//データセット
				setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleCreateCSV1 = () => {
	
		const getDateText = (date_time) => {
			const dateObj      = new Date(Number(date_time * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return(dateText);
		};
	
		const csvData = [];
		
		//対象データの抽出
		for(const x of data){
			if(selectIDs.includes(x.order_id)){
				for(const item of x.items){
					const params = {
						order_id : x.order_id,
						user_id : x.user_id,
						email : x.email,
						user_no : x.user_no,
						user_name : x.user_name,
						zip : x.zip,
						addr : x.addr,
						company : x.company,
						person : x.person,
						tel : x.tel,
						fax : x.fax,
						mobile : x.mobile,
						item_id : item.item_id,
						product_no : item.product_no,
						product_name : item.product_name,
						gousu : item.gousu,
						sub_price : item.sub_price,
						price : item.price,
						unit_price : item.unit_price,
						piece : item.piece,
						minimum_unit : item.minimum_unit,
						category1_name : item.category1_name,
						category1_code : item.category1_code,
						category2_name : item.category2_name,
						category2_code : item.category2_code,
						genre_name : item.genre_name,
						genre_code : item.genre_code,
						catalog_no : item.catalog_no,
						keyword : item.keyword,
						sort : item.sort,
						boxed_num : item.boxed_num,
						url : item.url,
						pre_order_flag : item.pre_order_flag,
						pre_order_request : item.pre_order_request,
						stock_flag : item.stock_flag,
						date_add : getDateText(x.date_add),
						date_edit : getDateText(x.date_edit),
					};
					csvData.push(params);
				}
			}
		}

		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName1('order1_' + dateText + '.csv');

		//CSVデータのセット
		setCSV1(csvData);
	};
	
	const handleCreateCSV2 = () => {
	
		const getDateText = (date_time) => {
			const dateObj      = new Date(Number(date_time * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			//const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			//const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = String('' + year + month + day);
			return(dateText);
		};
	
		const csvData = [];
		
		//対象データの抽出
		for(const x of data){
			if(selectIDs.includes(x.order_id)){
				for(const item of x.items){
					const params = {
						'得意先コード' : x.user_no,
						'受注日': getDateText(x.date_add),
						'直送区分': '0',
						'JANコード': item.item_id,
						'発注数': item.piece ?? '0',
						'客注番号': '',
						'備考': x.note,
						'web受注番号': x.order_id,
						'注残': x?.pre_order_flag ? '1' : '0',
						'注残管理' : x?.pre_order_request ? '1' : '0',
						'送料' : x?.shipping ?? '0',
					};
					csvData.push(params);
				}
			}
		}

		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName2('order2_' + dateText + '.csv');

		console.log(JSON.stringify(csvData));
		
		//CSVデータのセット
		setCSV2(csvData);
	};
	
	const handleChangeStatus = async (event) => {
		
		try {
			
			//Formオブジェクトの取得
			const order_id = event.target.name;
			const status   = event.target.value;
			
			const params ={
				order_id : order_id,
				status : status,
			};
			//console.log(params);
			
			//更新
			const ref = await API.Order.Update(params);
			console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleClose = () => {
		setAlert([]);
		setReload(reload+1);
	};
	
	const handleShowFilter = () => {
		setShowFilter(showFilter ? false : true);
		sessionStorage.setItem("showFilter", showFilter ? 'false' : 'true');
	};

	const formatSelect = (cell, row) => {
		return (
			<div className="text-center">
				<Form>
					<Form.Control name={row.order_id} as="select" defaultValue={cell} onChange={handleChangeStatus} >
						<option value="">----</option>
						{['注文の登録完了', '在庫を確認中です', '商品を確保しました', '商品の出荷をしています', '配達手続きが完了しました'].map((item, index) => <option key={index} value={item}>{item}</option>)}
					</Form.Control>
				</Form>
			</div>
		);
	};
	
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/admin/order/' + row.order_id + '/')}>
					詳細
				</Button>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatItem = (cell, row) => {
		if(cell.length === 1){
			return (
				<div>
					{cell[0].product_name}
				</div>
			);
		}
		else{
			return (
				<div>
					{cell[0].product_name}、他{(cell.length - 1)}点
				</div>
			);
		}
	};
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};

	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'date_add',
			text: '受注日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'user_no',
			text: '取引先コード',
			sort: true,
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'user_name',
			text: '取引先名',
			sort: true,
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'total_price',
			text: '受注金額',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'items',
			text: '受注品',
			sort: true,
			formatter: formatItem,
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'note',
			text: '連絡事項',
			sort: true,
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'status',
			text: '受注ステータス',
			sort: true,
			formatter: formatSelect,
			filter: showFilter ? textFilter() : null,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				
				<Row>
					<Col sm={12}>
						<h4>受注情報</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2}>
									<Button variant="danger" type="button" block="true" onClick={() => history.push('/admin/order/new/')} >新規登録</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　<font color="white">{ view.length ? view.length.toLocaleString() : '0' }</font>　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={4}>
									<input type="checkbox" name="show_filter" value="1" checked={showFilter} onChange={handleShowFilter}/> フィルター表示
								</Col>
								<Col sm={2} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'order_id'} data={view} columns={columns} checkbox={true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} sort={ { field: 'date_add', order: 'desc'} } />
					</Col>
				</Row>
				<CSVDownload data={csv1} filename={csvName1} onClick={handleCreateCSV1}	>
					CSV出力
				</CSVDownload>　
				<CSVDownload data={csv2} filename={csvName2} onClick={handleCreateCSV2}	>
					受注データCSV出力
				</CSVDownload>
			</Container>
		);
	}
};

export default withRouter(Default);
